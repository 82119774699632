import React from 'react'
import Div from '../Div'

export default function TextWidget({logoSrc, logoAlt, text, logoStyle, textStyle}) {
  return (
    <Div className="cs-text_widget">
      { logoStyle ? 
        <img src={logoSrc} alt={logoAlt} style={logoStyle}/>
        :
        <img src={logoSrc} alt={logoAlt} />
      }
      { textStyle ?
        <p style={textStyle}>{text}</p>
      :
        <p>{text}</p>      
      }
    </Div>
  )
}
