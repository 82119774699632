import React from 'react'
import { useState } from 'react'
import PricingTable from '.'
import Section from '../Div'
import Spacing from '../Spacing'

export default function PricingTableList() {
  const [tab, setTab] = useState('completo')
  return (
    <Section className="position-relative">
      <ul className="cs-tab_links cs-style1 cs-mp0 cs-primary_font">
        <li className={tab === 'completo' ? "active" : ""} onClick={()=>setTab('completo')}>Completo</li>
        <li className={tab === 'basico' ? "active" : ""} onClick={()=>setTab('basico')}>Wordpress</li>
      </ul>
      <Section className="row">
        <Section className="col-lg-4">
          {tab==='completo' && (
            <PricingTable 
              title='Starter'
              price='4.500'
              currency='R$'
              introduction='Para pequenas e médias empresas que procuram se destacar e serem reconhecidas na internet'
              features={['Identidade visual', 'Aplicações de identidade visual', 'Cartão de visita impresso', 'Landing Page com tecnologia de ponta (React.JS) ', 
              'Hospedagem Gratuita', 'E-mail´s empresáriais ilimitados', 'Tagueamento completo (Rastreamento das ações do público em sua página)']}
              btnText='Purchase Now'
              btnLink='/'
            />
          )}
          {tab==='basico' && (
            <PricingTable 
              title='Starter'
              price='2.500'
              currency='R$'
              introduction='Para empresas novas ou que ainda não possuem estrutra digital na internet'
              features={['Static responsive website', 'Video marketing', 'Keywords research', 'Facebook campaign', 'eCommerce solution', 'Google adword']}
              btnText='Purchase Now'
              btnLink='/'
            />
          )}
          <Spacing lg='25' md='25'/>
        </Section>
        <Section className="col-lg-4">
         {tab==='completo' && (
            <PricingTable 
              title='Premium'
              price='8.700'
              currency='R$'
              introduction='Para pequenas e médias empresas que procuram alavancar seus lucros e público na internet'
              features={['Identidade visual', 'Aplicações de identidade visual', 'Cartão de visita impresso', 'Site Institucional com tecnologia de ponta (React.JS) ', 
              'Hospedagem Gratuita', 'E-mail´s empresáriais ilimitados', 'Tagueamento completo (Rastreamento das ações do público em sua página)', 
              'Estratégia digital de lançamento', 'Campanha no Google Ads e Meta Ads', 'Criativos para campanha', 'Assessoria de marketing digital']}
              btnText='Purchase Now'
              btnLink='/'
            />
          )}
          {tab==='basico' && (
            <PricingTable 
              title='Premium'
              price='4.500'
              currency='R$'
              introduction='Para pequenas e médias empresas que desejam começar a vender através da internet'
              features={['Static responsive website', 'Video marketing', 'Keywords research', 'Facebook campaign', 'eCommerce solution', 'Google adword']}
              btnText='Purchase Now'
              btnLink='/'
            />
          )}
          <Spacing lg='25' md='25'/>
        </Section>
        <Section className="col-lg-4">
          {tab==='completo' && (
            <PricingTable 
              title='Enterprise'
              price='Personalizado'
              currency=''
              introduction='Para médias e grandes empresas que buscam o desenvolvimento de um serviço de estrutura digital completa'
              features={['Static responsive website', 'Video marketing', 'Keywords research', 'Facebook campaign', 'eCommerce solution', 'Google adword']}
              btnText='Purchase Now'
              btnLink='/'
            />
          )}          
          <Spacing lg='25' md='25'/>
        </Section>
      </Section>
    </Section>
  )
}
