import React, { useEffect } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import PortfolioSlider from '../Slider/PortfolioSlider';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import PricingTableList from '../PricingTable/PricingTableList';
import TeamSlider from '../Slider/TeamSlider';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';

export default function Home() {

  const bgImageUrl = '/images/service_bg.png';
  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'Behance',
      links: '/',
    },
    {
      name: 'Twitter',
      links: '/',
    },
  ];

  // FunFact Data
  const funfaceData = [
    {
      title: 'Cliente Atendidos',
      factNumber: '32',
    },
    {
      title: 'Anos no Mercado',
      factNumber: '5',
    },
    {
      title: 'Conversão',
      factNumber: '91.05%',
    },
    {
      title: 'Gerenciados em Anúncios',
      factNumber: '487K',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="Sua empresa no degrau acima"
        subtitle="Chegamos para transformar o jeito de criar, inovar e inspirar. Acreditamos no potencial para
        transformar e mudar a tecnologia com marketing digital."
        btnText="Solicite um orçamento"
        btnLink="/contact"
        scrollDownId="#service"
        socialLinksHeading="Siga-nos"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/hero_bg.jpg"
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <div style={{backgroundImage: `url(${bgImageUrl})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
        <div className="container">
          <FunFact
            variant="cs-type1"
            title="Conheça a Upstairs"
            subtitle=" Atualidade é o nosso sobrenome. Estamos sempre evoluindo
            conforme as novas tecnologias do mercado digital, que muitas vezes é um tanto quanto
            desafiador. Buscamos sempre colocar sua empresa degraus acima, por meio de uma visão
            ampla sobre o seu público e sobre os seus concorrentes. Vamos subir essas escadas juntos, a
            sua era digital está só começando."
            data={funfaceData}
          />
        </div>      
      {/* End FunFact Section */}

      {/* Start Service Section */}
      <Spacing lg="75" md="40" />
      <Div id="service" style={{paddingTop: '5rem', paddingBottom: '5rem',}}>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5">
              <SectionHeading
                title="Como podemos ajudar"
                subtitle="Serviços"
                paragraph="Se você é proprietário de uma empresa, seja ela do ramo que for, você certamente busca ampliar sua marca, impactando novas pessoas. 
                A internet é a melhor amiga que você pode ter para alavancar as suas negociações e te colocar como ponto de referência no seu setor.
                            
                Desenvolvemos todo o processo ao seu lado, e proporcionamos as condições para que você usufrua da tecnologia que permite mostrar sua 
                empresa para mais pessoas."
                btnText="Solicite seu orçamento"
                btnLink="#"
                btnTextS="Nos chame no WhatsApp"
                btnLinkS="#"
              />
            </Div>
            <Div className="col-xl-7">
              <Div className="row" style={{paddingTop: '20%', paddingLeft: '0.7rem',}}>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="UI/UX Design"
                    link="/service/service-details"
                    src="/images/service_1.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="React.js Development"
                    link="/service/service-details"
                    src="/images/service_2.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Digital Marketing"
                    link="/service/service-details"
                    src="/images/service_3.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Brand"
                    link="/service/service-details"
                    src="/images/service_4.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}
    

      {/* Start Pricing Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Planos e preços <br/>únicos e imbatíveis"
          subtitle="Planos e Preços"
        />
        <Spacing lg="85" md="40" />
        <PricingTableList />
      </Div>
      {/* End Pricing Section */}
      </div>

      {/* Start Portfolio Section */}
      <Spacing lg="75" md="40" />
      <Div>
        <Div className="container">
          <SectionHeading
            title="Projetos de impacto"
            subtitle="Nosso portfólio"
            variant="cs-style1 text-center"
          />
          <Spacing lg="45" md="22" />
        </Div>
        <PortfolioSlider />
        <Spacing lg="75" md="40" />

      </Div>
      {/* End Portfolio Section */}

      {/* Start Testimonial Section */}
      <TestimonialSlider />
      {/* End Testimonial Section */}

      {/* Start MovingText Section */}
      <Spacing lg="75" md="35" />
      <MovingText text="Sua empresa no degrau acima" />
      <Spacing lg="75" md="35" />
      {/* End MovingText Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Conte seu <i>projeto</i> para nós <br />queremos desenvolvê-lo <i>juntos</i> de você"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/funfact_bg.png"
        />
      </Div>
      {/* End CTA Section */}

    </>
  );
}
