import React from 'react'
import Div from '../Div'
import './funfact.scss'
import { GoVerified } from 'react-icons/go'

export default function FunFact({variant, title, subtitle, data}) {
  return (
    <Div className={variant ? `cs-funfact_wrap ${variant}`: 'cs-funfact_wrap'}  style={{backgroundImage: 'url(./images/funfact_bg.png)'}} >
      <Div className="cs-funfact_shape"/>
      <Div className="cs-funfact_left">
        <Div className="cs-funfact_heading">
          <p className='cm-desc'><i>Sobre nós</i> &nbsp;  <GoVerified /></p>
          <h3 className='cs-bold'>{title}</h3>
          <p className='cm-p'>{subtitle}</p>
        </Div>
      </Div>
      <Div className="cs-funfact_right">
        <Div className="cs-funfacts">
        {data.map((item, index) => (
          <Div className="cs-funfact cs-style1" key={index}>
            <Div className="cs-funfact_number cs-primary_font cs-semi_bold cs-primary_color"><span/>{item.factNumber}</Div>
            <Div className="cs-funfact_text">
              <span className="cs-accent_color">+</span>
              <p className='cs-semi_bold' style={{color: '#111111'}}>{item.title}</p>
            </Div>
          </Div>
          ))}
        </Div>
      </Div>
    </Div>
  )
}
