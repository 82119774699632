import React from 'react'
import parse from 'html-react-parser';
import './cta.scss'
import Button from '../Button';
import Div from '../Div';
import Spacing from '../Spacing';
import { BsWhatsapp } from 'react-icons/bs';
import { HiArrowNarrowRight } from 'react-icons/hi';

export default function Cta({title, bgSrc, variant}) {

  const showContato = () => {
    document.getElementById('side-bar-head').classList.add('active');    
  }


  return (
    <Div className={`cs-cta cs-style1 cs-bg text-center cs-shape_wrap_1 cs-position_1 ${variant?variant:''}`} style={{backgroundImage: `url(${bgSrc})`}}>
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-cta_in">
        <h2 className="cs-cta_title cs-semi_bold cs-m0">{parse(title)}</h2>
        <Spacing lg='70' md='30' />
        <div className='row justify-content-center'>
            <div className='col-md-5 col-xl-5'>
              <p className='cm-p' style={{paddingTop: '2rem'}}>
                Atualidade é o nosso sobrenome. Estamos sempre evoluindo conforme as novas tecnologias do mercado digital, 
                que muitas vezes é um tanto quanto desafiador. Buscamos sempre colocar sua empresa degraus acima, por meio de uma 
                visão ampla sobre o seu público e sobre os seus concorrentes. Vamos subir essas escadas juntos, a sua era digital está só 
                começando.
              </p>
            </div>
            <div className='col-md-5 col-xl-5'>
              <h4 style={{padding: '0rem 3rem 0 3rem'}}>Como você pode falar com a gente</h4>
              <a href='https://wa.me/message/7UZXHEAHMFRKK1' target='_blank' rel='noreferrer' className='cm-btn_wpp' style={{marginTop: '1rem'}}><BsWhatsapp style={{fontSize: '1.5rem', marginRight: '0.4rem'}} /> (11) 9 5190 4897</a>
              <button onClick={() => showContato()} className='cm-btn' style={{marginTop: '1rem', width: '100%'}}>Outras formas de contato <HiArrowNarrowRight style={{fontSize: '1.5rem', marginLeft: '0.4rem'}} /></button>
            </div>
        </div>
      </Div>
    </Div>
  )
}
