import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import SocialWidget from '../Widget/SocialWidget';
import Newsletter from '../Widget/Newsletter';
import './header.scss';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import Div from '../Div';
import { BiPhoneOutgoing } from 'react-icons/bi';
import { BsWhatsapp } from 'react-icons/bs';
import { MdOutgoingMail } from 'react-icons/md';

export default function Header({ variant }) {
  const [isSticky, setIsSticky] = useState(false);
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  useEffect(() => {
    //Deixar confiruração abaixo ativa para a navbar ficar sempre ativa
    setIsSticky(true);

    //efeito sticky na navbar
    /*
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });*/
  }, []);

  return (
    <>
      <header
        className={`cs-site_header cs-style1 text-uppercase ${
          variant ? variant : ''
        } cs-sticky_header ${isSticky ? 'cs-sticky_header_active' : ''}`}
      >
        <Div className="cs-main_header">
          <Div className="container">
            <Div className="cs-main_header_in">
              <Div className="cs-main_header_left">
                <Link className="cs-site_branding" to="/">
                  <img src="/images/logo.svg" width={'200px'} height={'auto'} alt="Logo" />
                </Link>
              </Div>
              <Div className="cs-main_header_center">
                <Div className="cs-nav cs-primary_font cs-semi_bold">
                  <ul
                    className="cs-nav_list"
                    style={{ display: `${mobileToggle ? 'block' : 'none'}`, textTransform: 'capitalize', fontWeight: 600 }}
                  >
                    <li>
                      <NavLink to="/" onClick={() => setMobileToggle(false)}>
                        Início
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="about"
                        onClick={() => setMobileToggle(false)}
                      >
                        Conheça a Upstairs
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="service"
                        onClick={() => setMobileToggle(false)}
                      >
                        Serviços
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="portfolio"
                        onClick={() => setMobileToggle(false)}
                      >
                        Nossos Clientes
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="contato"
                        onClick={() => setMobileToggle(false)}
                      >
                        Contato
                      </NavLink>
                    </li>

                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? 'cs-munu_toggle cs-toggle_active'
                        : 'cs-munu_toggle'
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </Div>
              </Div>
              <Div className="cs-main_header_right">
                <Div className="cs-toolbox">
                  <span
                    className="cs-icon_btn"
                    onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
                  >
                    <span className="cs-icon_btn_in">
                      <span />
                      <span />
                      <span />
                      <span />
                    </span>
                  </span>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </header>

      <Div
        id='side-bar-head'
        className={
          sideHeaderToggle ? 'cs-side_header active' : 'cs-side_header'
        }
      >
        <button
          className="cs-close"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div
          className="cs-side_header_overlay"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div className="cs-side_header_in">
          <Link className="cs-site_branding" to="/">
            <img src="/images/logo.svg" style={{marginBottom: '1rem'}} width={'250px'} height={'auto'} alt="Logo" />
          </Link>
          <Div className="cs-side_header_box">
            <h2 className="cs-side_header_heading" style={{color: '#f5f5f5'}}>
            Conte seu projeto para nós queremos desenvolvê-lo juntos de você
            </h2>
          </Div>
          <Div>
            <a href='https://wa.me/message/7UZXHEAHMFRKK1' target='_blank' rel='noreferrer' className='cm-btn_wpp' style={{marginTop: '1rem'}}><BsWhatsapp style={{fontSize: '1.5rem', marginRight: '0.4rem'}} /> (11) 9 5190 4897</a>
            <a href='tel:5511951904897' target='_blank' rel='noreferrer' className='cm-btn_transparent' style={{marginTop: '1rem'}}><BiPhoneOutgoing style={{fontSize: '1.5rem', marginRight: '0.4rem'}} /> (11) 9 5190 4897</a>
            <a href='mailto:comercial@agenciaupstairs.com.br' target='_blank' rel='noreferrer' className='cm-btn_transparent' style={{marginTop: '1rem'}}><MdOutgoingMail style={{fontSize: '1.5rem', marginRight: '0.4rem'}} /> comercial@agenciaupstairs.com.br</a>
          </Div>
          <Div className="cs-side_header_box">
            <div style={{display: 'flex', justifyContent: 'center', paddingTop: '2rem'}}>
              <SocialWidget />
            </div>
          </Div>
        </Div>
      </Div>
    </>
  );
}
